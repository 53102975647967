<template>
    <div>
        <v-icon :color="statoColor">mdi-circle</v-icon>
        {{ statoLabel }}
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    props: {
        stato: String,
    },
    computed: {
        ...mapState(['dataset']),
        statoColor() {
            return {
                'da_associare': 'gray',
                'da_pianificare': 'blue',
                'in_corso_di_pianificazione': 'light-green',
                'pianificato': 'lime',
                'eseguito': 'light-blue',
                'non_validato': 'red',
                'validato': 'green',
                'validato-consip': 'orange',
                'non-validato-consip': 'red',
                'sospeso': 'cyan',
                'annullato': 'red',
                'sostituito': 'blue',
            }[this.stato];
        },
        statoLabel() {
            const stato = this.dataset['ordinativo_stato'].find(row => row.value === this.stato);
            return stato['text'];
        }
    }
}
</script>
